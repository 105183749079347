/* @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .th-style{
        @apply whitespace-nowrap text-sm font-medium bg-violet-200 border border-gray-400 text-gray-900 px-2 py-1 text-left;
    }
    .tr-style{
        @apply bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-600;
        &:nth-child(even) {
            @apply bg-white;
            &.dark {
                @apply bg-gray-700
            }
        }
        &:nth-child(odd) {
            @apply bg-gray-50 ;
            &.dark {
                @apply bg-gray-800
            }
            
        } 
    }
    .td-style{
        @apply text-sm text-gray-900 border border-gray-400 py-2 px-3 font-light whitespace-nowrap;
    }
    .mobile-sec-menu-a{
        @apply block pl-3 pr-4 py-2 border-l-4 border-gray-300 text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out;
    }
}